<template>
	<div class="team-members">
		
		<div class="row" v-if="!$store.state.active || ($store.state.active && 'list users' in $store.state.permissions)">

			<div class="col-12">
				<h6 class="oml-h6Title">{{ $t('Members') }} <i v-if="!$store.state.active || ($store.state.active && 'add users' in $store.state.permissions)" @click="CheckPlans" class="fa fa-plus-circle" style="color:#377cff;font-size: 17px;margin-left: 7px;"></i>
				
				<!--i class="fa fa-spinner fa-spin" style="font-size: 18px;" v-if="globalLoading"></i--></h6>
				
				<div class="p-desc">
					<p>{{ $t('desc_member') }}</p>
				</div>
			</div>
			<div class="col-6 text-right my-auto">
				<!--a href="#" @click="CheckPlans" class="ohmylead-btn-blue add_membre_btn pull-right" style="margin-top: 0 !important;">Add new member</a>
				<b-button v-if="!$store.state.active || ($store.state.active && 'add users' in $store.state.permissions)" @click="CheckPlans" variant="primary" id="button-add-member" class="oml-btn">
					<i class="fa fa-spinner fa-spin" style="font-size: 18px;" v-if="LoadingAdd"></i> {{ $t('AddNewMember') }}
				</b-button-->
			</div>
			
			<div class="col-12 mx-auto">
				
				<div class="heading-invit-membre">
					
					
					<div class="row">
						<div class="col-12">
							
						</div>
						<div class="col-12">
							
							<div class="table-grid member-table">
								<table class="table-md"><!--  table-bordered table-hover table-striped -->
									<thead>
										<tr>
											<th v-for="(item, key) in columns" v-bind:key="key">
												<div>
													{{ item.title }}
													<div class="fa fa-sort-amount-asc" :class="item.title != '' ? 'asc' : 'dsc'"></div>
												
													<!--th v-for="(item, key) in columns" @click="sortBy(item.column)" :class="{ active: sortKey == item.column }" v-bind:key="key"-->
														
											
													<!--div class="arrow" :class="sortOrders[item.column] > 0 ? 'asc' : 'dsc'"></div-->
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, key) in arrayMembers" v-bind:key="key">
											<td>{{ item.name }}</td>
											<td :title="item.email">{{ item.email | maxLength }}</td>
											<td>{{ $t(item.role) }}</td>
											<td>
												<div v-if="(item.role != 'account_owner' && currentClient.owner_id == $auth.user().id) || ($store.state.active && ('edit users' in $store.state.permissions || 'delete users' in $store.state.permissions) && item.role != 'account_owner') || activatedAdmin == true " class="text-center"><!-- $auth.user().id != item.id -->
													
													<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right no-caret>
														<template v-slot:button-content>
															<i class="fa fa-ellipsis-v dropdown-icon"></i>
														</template>
														<b-dropdown-item v-if="!$store.state.active || ($store.state.active && 'edit users' in $store.state.permissions)" @click="userAction('edit', item)"><i class="fa fa-pencil pr-1"></i> {{ $t('Edit') }}</b-dropdown-item>
														<b-dropdown-item   v-if="!$store.state.active || ($store.state.active && 'delete users' in $store.state.permissions)" @click="userAction('remove', item)"><i class="fa fa-trash pr-1"></i> {{ $t('Remove') }}</b-dropdown-item>
													</b-dropdown>
													
												</div>
												<div v-else>
													<center></center>
												</div>
												
											</td>
										</tr>
										<tr v-for="(item, key) in pendingInvitation" v-bind:key="`key-${key}`">
											<td><span class="badge badge-warning">{{ $t('InProcess') }}</span> <i style="font-size: 12px;">{{ item.invite_date }}</i></td>
											<td :title="item.email">{{ item.email | maxLength }}</td>
											<td>{{ $t(item.role) }}</td>
											<td>
												<div v-if="item.role != 'account_owner' && currentClient.owner_id == $auth.user().id">
													
													<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right no-caret>
														<template v-slot:button-content>
															<i class="fa fa-ellipsis-v dropdown-icon"></i>
														</template>
														<b-dropdown-item @click="userAction('link', item)"><i class="fa fa-clone pr-1"></i> {{ $t('InvitationLink') }}</b-dropdown-item>
														<b-dropdown-item @click="userAction('resend', item)"><i class="fa fa-paper-plane pr-1"></i> {{ $t('ResendInvitation') }}</b-dropdown-item>
														<b-dropdown-item @click="userAction('cancel', item)"><i class="fa fa-times-circle pr-1"></i> {{ $t('CancelInvitation') }}</b-dropdown-item>
													</b-dropdown>
													
												</div>
												<div v-else>
													<center></center>
												</div>
												
											</td>
										</tr>
										<tr v-for="(item, key) in invites" v-bind:key="`invit-${key}`">
											<td>
												<small>{{ item.team_name }}</small>
												<div>
													<span class="badge badge-primary">{{ $t('Invited') }}</span> <i style="font-size: 12px;">{{ item.invite_date }}</i>
												</div>
											</td>
											<td :title="item.email">{{ item.email | maxLength }}</td>
											<td>{{ $t(item.role) }}</td>
											<td>
												<div v-if="item.role != 'account_owner' && currentClient.owner_id == $auth.user().id">
													
													<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right no-caret>
														<template v-slot:button-content>
															<i class="fa fa-ellipsis-v dropdown-icon"></i>
														</template>
														<b-dropdown-item @click="userAction('accept', item)"><i class="fa fa-check-circle pr-1"></i> {{ $t('AcceptInvitation') }}</b-dropdown-item>
														<b-dropdown-item @click="userAction('cancel', item)"><i class="fa fa-trash pr-1"></i> {{ $t('IgnoreInvitation') }}</b-dropdown-item>
													</b-dropdown>
													
												</div>
												<div v-else>
													<center></center>
												</div>
												
											</td>
										</tr>
										
										<tr v-if="arrayMembers.length == 0 && pendingInvitation.length == 0 && invites.length == 0">
											<td :colspan="columns.length"><center>{{ $t('NoDataToDisplay') }} !</center></td>
										</tr>
										
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		
		<b-modal id="bv-modal-users" centered style="min-width:650px!important">
			<template v-slot:modal-title>
				{{ textTitle }}
			</template>
			<div class="d-block">
				<p><b>{{ $t('Workspace') }}: </b> {{ currentClient.name }} </p>
				<p v-if="!editMode">{{ $t('WorkspaceDesc') }}</p>
				<div class="form-group col-md-12 pl-0" v-if="!ShowLink">
					<label for="email">{{ $t('Email') }} <b style="color:red;">*</b></label>
					<input type="email" id="email" class="form-control" :placeholder="$t('TypeEmail')" v-model="userSelected.email" :disabled="editMode" />
					<small v-if="is_empty" style="color: red;"> * {{ $t('EmailAddressRequired') }} </small>
				</div>
				<div v-if="!ShowLink">
					<div class="rdios custom-control custom-radio custom-control-inline" v-for="(option, id) in options" v-bind:key="`id-${id}`" >
						<input type="radio" :id="`id-${option.value}`" name="customRadioInline1" class="custom-control-input" v-model="userSelected.role" :value="option.value" @change="checkPermissions()">
						<label class="custom-control-label" :for="`id-${option.value}`">
							{{ option.text }} <span :content="option.info" role-tooltip="hover me"><i class="fa fa-info-circle text-primary"></i></span>
						</label>
					</div>
				</div>
				<div v-else>
					<p>{{ $t('linkDesc') }}</p>
					<div class="form-group col-md-12 pl-0">
						<span @click="doCopy(myLink)" id="thelink">{{ myLink }}</span>
					</div>
				</div>
				
				
				
				
				
				
			</div>
			<div class="d-block" v-if="!ShowLink">
				<div class="permissions col-md-12 ">
					<b>{{ $t('ThisMemberCanAccessTo')}} :</b><br>
					
					<div>
						
							<div class="accordion" role="tablist">
							<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block v-b-toggle.accordion-1 class="buttoncollapse"><input v-model="this.permissionsGroups.Mysources['list sources']" type="checkbox"  :checked="this.permissionsGroups.Mysources['list sources']"  @change="changePermission('list sources',$event.target.checked)"> {{$t('MySources')}} <i v-if="this.showSources" class="fa fa-chevron-down"></i> <i v-if="this.showSources" class="fa fa-chevron-up"></i></b-button>
							</b-card-header>
							<b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel" v-if="this.showSources">
								<b-card-body>
									<div v-for="(item, key) in this.permissionsGroups.Mysources"  v-bind:key="key" class="form-contol permission">
										<label v-show="key != 'list sources'" :for="key">
											<input type="checkbox"  :id="key" :checked="item"  @change="changePermission(key,$event.target.checked)"> 
												
											{{getTranslated(key)}}
										</label>
									</div>
								</b-card-body>
							</b-collapse>
							</b-card>

							<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block v-b-toggle.accordion-2 class="buttoncollapse"><input type="checkbox"  :checked="this.permissionsGroups.Myleads['list leads']"  @change="changePermission('list leads',$event.target.checked)"> {{$t('MyLeads')}} <i v-if="this.showLeads" class="fa fa-chevron-down"></i><i v-if="this.showLeads" class="fa fa-chevron-up"></i></b-button>
							</b-card-header>
							<b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel" v-if="this.showLeads">
								<b-card-body>
									<div v-for="(item, key) in this.permissionsGroups.Myleads"  v-bind:key="key" class="form-contol permission">
										<label :for="key" v-show="key != 'list leads'">
										<input type="checkbox" :id="key" :checked="item"  @change="changePermission(key,$event.target.checked)"> {{getTranslated(key)}}
										</label>
									</div>
										<!--<div v-for="(item, key) in this.permissionsGroups.LeadsAccess"  v-bind:key="key" class="form-contol permission">
											<label :for="key">
											<input type="checkbox" :id="key" :checked="item"  @change="changePermission(key,$event.target.checked)"> {{getTranslated(key)}}
											</label>
										</div>-->
									
								</b-card-body>
							</b-collapse>
							</b-card>

							<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block v-b-toggle.accordion-5 class="buttoncollapse"><input type="checkbox"  :checked="this.permissionsGroups.Analytics['analytics']"  @change="changePermission('analytics',$event.target.checked)"> {{$t('Analytics')}} </b-button>
							</b-card-header>
						
							</b-card>

							<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block v-b-toggle.accordion-3 class="buttoncollapse"><input type="checkbox"  :checked="this.permissionsGroups.Destinations['list destination']"  @change="changePermission('list destination',$event.target.checked)"> {{$t('Destinations')}} </b-button>
							</b-card-header>
						
							</b-card>

							
							<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block v-b-toggle.accordion-4 class="buttoncollapse"><input type="checkbox"  :checked="this.permissionsGroups.Settings['list users'] || this.permissionsGroups.Settings['list status']"  @change="changeSettingsState($event.target.checked)"> {{$t('Settings')}} <i  class="fa fa-chevron-down"></i><i  class="fa fa-chevron-up"></i></b-button>
							</b-card-header>
							<b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
								<b-card-body>
									
									<div v-for="(item, key) in this.permissionsGroups.Settings"  v-bind:key="key" >
										<div class="form-contol permission" v-if='key!="list users" &&  key!="list status"'>
											<label :for="key">
												<input :disabled="disabledPermissions(key)" type="checkbox" :id="key" :checked="item"  @change="changePermission(key,$event.target.checked)"> {{getTranslated(key)}}
											
											</label>
										</div>
										<div class="form-contol" v-else>
											<label :for="key">
												<input  type="checkbox" :id="key" :checked="item"  @change="changePermission(key,$event.target.checked)"> {{getTranslated(key)}}
											</label>
										</div>
										
									</div>
								</b-card-body>
							</b-collapse>
							</b-card>

							

						</div>
					</div>

				</div>
			</div>
			<template v-slot:modal-footer>
				<b-button @click="sendInvitation()" variant="primary" :disabled="loadingAction" v-if="!ShowLink" class="oml-btn">
					<i class="fa fa-spinner fa-spin" v-if="loadingAction"></i> 
					<i :class="[iconButton]" v-else></i> {{ textButton }} 
				</b-button>
				<b-button @click="doCopy(myLink)" variant="primary" :disabled="loadingAction" class="oml-btn" v-else>
					<i class="fa fa-spinner fa-spin" v-if="loadingAction"></i> 
					<i class="fa fa-clone" v-else></i>  {{ $t('copyButton') }}
				</b-button>
			</template>
		</b-modal>

		<b-modal id="bv-modal-link" centered>
			<template v-slot:modal-title>
				{{ $t('thisIsTheLink') }}
			</template>
			<div class="d-block">
				
				<p>{{ $t('linkDesc') }}</p>
				<div class="form-group col-md-12 pl-0">
					<span @click="doCopy(myLink)" id="thelink">{{ myLink }}</span>
				</div>
			</div>
			<template v-slot:modal-footer>
				<b-button @click="doCopy(myLink)" variant="primary" :disabled="loadingAction">
					<i class="fa fa-spinner fa-spin" v-if="loadingAction"></i> 
					<i class="fa fa-clone" v-else></i>  {{ $t('copyButton') }}
				</b-button>
			</template>
		</b-modal>
		
		<b-modal id="modal-delete-user" centered hide-footer title="Please Confirm">
			<div>
				<div class="row">
					<div class="col-12 mb-1">
						<p class="m-0" style=" color: #000; font-size: 14px; ">{{ $t('YouWantToRemove') }} :</p>
						<b style="word-break: break-word;">{{ userSelected.email }}</b>
					</div>
				</div>
				<div class="row">
					<div class="col-12 text-right">
						<b-button size="sm" variant="success" @click="deleteUser()" :disabled="loadingAction">
							<i class="fa fa-spinner fa-spin" v-if="loadingAction"></i> {{ $t('Yes') }}
						</b-button>
					</div>
				</div>
			</div>
		</b-modal>
		
		<b-modal id="modal-cancel-invitation" centered hide-footer title="Please Confirm">
			<div>
				<div class="row">
					<div class="col-12 mb-1">
						<p class="m-0" style=" color: #000; font-size: 14px; ">{{ $t('InvitationMemberDesc') }}</p>
						<!--b style="word-break: break-word;">{{ userSelected.email }}</b-->
					</div>
				</div>
				<div class="row">
					<div class="col-12 text-right">
						<b-button size="sm" variant="success" @click="CancelInvit()" :disabled="loadingAction">
							<i class="fa fa-spinner fa-spin" v-if="loadingAction"></i> {{ $t('Yes') }}
						</b-button>
					</div>
				</div>
			</div>
		</b-modal>
		
		<!--invitation-modal :show="showInviteModal" ref="invModal"></invitation-modal-->
			<div v-if="$store.state.active && !Object.keys($store.state.permissions).includes('list users')">
				<h5 class="sync-text">
					<img src="/img/svg/info-circle.svg" />
					<span style="text-decoration:none"> {{ $t('Noaccess') }}</span>
				</h5>
			</div>		
    </div>
	
</template>

<script>
	export default {
		name: 'team',
		data() {
			return {
				showSettings : false,
				myLink : '',
				ShowLink: false,
				showSources : false,
				showLeads : false,
				permissionsTranslate:{
					"add sources":"Ajouter des sources",
					"edit sources":"Modifier les sources",
					"delete sources":"Supprimer les sources",
					"add users":"Ajouter des membres",
					"edit users":"Modifier les membres",
					"delete users":"Supprimer les membres",
					"add integrations":"Ajouter des destinations",
					"edit integrations":"Modifier les destinations",
					"delete integrations":"Supprimer les destinations",
					"list leads":"Mes leads",
					"add leads":"Ajouter les leads",
					"edit leads":"Modifier les leads",
					"qualify leads":"Changer le statut",
					"add note to leads":"Ajouter les notes",
					"enrich leads":"Enrich du lead",
					"delete leads":"Supprimer les leads",
					"add status":"Ajouter un statut",
					"edit status":"Modifier les statuts",
					"delete status":"Supprimer les statuts",
					"show hide colums":"Afficher / masquer les colonnes",
					"list users":"Membres : ",
					"list status":"Statuts : ",
					"list sources":"Sources",
					"edit plan":"Modifier le plan",
					"show plan":"Voir le plan",
					"call leads":"Appeler les leads",
					"send SMS":"Envoyer des SMS",
					"detail lead":"Détails du Lead",
					"list destination":"Destinations",
					"analytics":"Statistiques",
					"download leads" : "Télécharger les leads",
					"bulk action" : "Actions groupées",
					"send email to leads":"Envoyer des e-mails",
					"manage all leads" : "Les leads d'autres utilisateurs",
					//"manage assigned leads" : "Seulement ses leads",
					"manage assigned and unassigned leads" : "Leads Non attribués",
					"assigne" : "Attribur le lead à",
					"send to" : "Envoyer à"
				},
				permissionsTranslateEn:{
					"add sources":"Add sources",
					"edit sources":"Edit sources",
					"delete sources":"Delete sources",
					"add users":"Add members",
					"edit users":"Edit members",
					"delete users":"Delete members",
					"add integrations":"Add integrations",
					"edit integrations":"Edit integrations",
					"delete integrations":"Delete integrations",
					"list leads":"My Leads",
					"add leads":"Add leads",
					"edit leads":"Edit leads",
					"qualify leads":"Change status",
					"add note to leads":"Add notes",
					"enrich leads":"Enrich du lead",
					"delete leads":"Delete leads",
					"add status":"Add status",
					"edit status":"Edit status",
					"delete status":"Delete status",
					"show hide colums":"Show / hide columns",
					"list users":"Members : ",
					"list status":"Status : ",
					"list sources":"My sources",
					"edit plan":"Edit plan",
					"show plan":"Show plan",
					"call leads":"Call leads",
					"send SMS":"Send SMS",
					"detail lead":"Show details",
					"list destination":"Destinations",
					"analytics":"Analytics",
					"download leads" : "Download leads",
					"bulk action" : "Bulk actions",
					"send email to leads":"Send Emails",
					"manage all leads" : "Other users leads",
					//"manage assigned leads" : "Only his leads",
					"manage assigned and unassigned leads" : "Unassigned",
					"assigne" : "Assing lead to",
					"send to" : "send to"
				},
				permissionsGroups:{
					"Mysources":
						{
							"add sources" : false,
							"edit sources" : false,
							"delete sources" :false,
							"list sources" :false,
							
						},
					"Myleads":{
							
							"add leads" : false,
							"edit leads" : false,
							"qualify leads" : false,
							"add note to leads" : false,
							"send email to leads" : false,
							//"enrich leads" : false,
							//"call leads" : false,
							//"sms leads" : false,
							"delete leads" : false,
							"detail lead" : false,
							"show hide colums" : false,
							"download leads" : false,
							"list leads" : false,
							"bulk action" : false,
							"manage all leads" :  false,
							"manage assigned and unassigned leads" : false,
							"assigne" : false,
							"send to" : false
					},
					"LeadsAccess":{
							"manage all leads" :  false,
							//"manage assigned leads" : false,
							"manage assigned and unassigned leads" : false
					},
					"Settings":{
							"list users" : false,
							"add users" : false,
							"edit users" : false,
							"delete users" : false,
							"list status" : false,
							"add status" : false,
							"edit status" : false,
							"delete status" : false,
							//"system owner" : false,
							//"edit plan" : false,
							//"show plan" : false,
							
					},
					"Destinations":{
						//"add integrations" : false,
						//"edit integrations" : false,
						//"delete integrations" : false,
						"list destination" : false,
					},
					"Analytics":{
						//"add integrations" : false,
						//"edit integrations" : false,
						//"delete integrations" : false,
						"analytics" : false,
					}
					
				},
				//permissions : {},
				customPermissions : [],
				permissions:{
					"roles": {
						"viewer": {
						"permissions": [
							"list leads",
							"show hide colums",
							"list sources",
							"detail lead",
							//"manage assigned leads"
						],
						"active": false
						},
						"editor": {
						"permissions": [
							"add sources",
							"edit sources",
							"delete sources",
							"add integrations",
							"edit integrations",
							"delete integrations",
							"list leads",
							"add leads",
							"edit leads",
							"qualify leads",
							"add note to leads",
							"enrich leads",
							"delete leads",
							"add status",
							"edit status",
							"delete status",
							"show hide colums",
							"list status",
							"list sources",
							"list integrations",
							"detail lead",
							"download leads",
							"send email to leads",
							//"manage assigned leads",
							"manage assigned and unassigned leads",
							"assigne",
							"send to"
						],
						"active": false
						},
						"admin": {
						"permissions": [
							"add sources",
							"edit sources",
							"delete sources",
							"add users",
							"edit users",
							"delete users",
							"add integrations",
							"edit integrations",
							"delete integrations",
							"list leads",
							"add leads",
							"edit leads",
							"qualify leads",
							"add note to leads",
							"enrich leads",
							"delete leads",
							"add status",
							"edit status",
							"delete status",
							"show hide colums",
							"list users",
							"list status",
							"list sources",
							"edit plan",
							"show plan",
							"send email",
							"call lead",
							"send SMS",
							"detail lead",
							"list integrations",
							"system owner",
							"analytics",
							"download leads",
							"bulk action",
							"send email to leads",
							"list smtp",
							"list destination",
							"manage all leads",
							//"manage assigned leads",
							"manage assigned and unassigned leads",
							"assigne",
							"send to"
						],
						"active": false
						},
						"custom": {
							"permissions": {
								"add sources": 1,
								"edit sources": 2,
								"delete sources": 3,
								"add users": 4,
								"edit users": 5,
								"delete users": 6,
								"add integrations": 7,
								"edit integrations": 8,
								"delete integrations": 9,
								"list leads": 10,
								"add leads": 11,
								"edit leads": 12,
								"qualify leads": 13,
								"add note to leads": 14,
								"enrich leads": 16,
								"delete leads": 19,
								"add status": 20,
								"edit status": 21,
								"delete status": 22,
								"show hide colums": 23,
								"list users": 25,
								"list status": 27,
								"edit plan": 30,
								"list sources": 32,
								"list integrations": 33,
								"detail lead": 34,
								"show plan": 35,
								"analytics": 38,
								"download leads" : 37,
								"bulk action" : 47,
								"send email to leads" : 34,
								//"add smtp" : 39,
								//"edit smtp" : 40,
								//"delete smtp" : 41,
								"list smtp"  : 37,
								//"add destination" : 43,
								//"edit destination" : 44,
								//:"delete destination" : 45,
								"list destination" : 46,
								"manage all leads" :  43,
								//"manage assigned leads" : 44,
								"manage assigned and unassigned leads" : 45,
								"assigne" : 42,
								"send to" : 46
							},
						"active": true
						}
					}}, 
				columns: [
					{ title: 'Name'		   , column: 'name'	  		},
					{ title: 'Email'  	   , column: 'email'	  	},
					{ title: 'Role'		   , column: 'role'		  	},
					{ title: 'Actions'	   , column: 'actions'	  	},
				],
				// selected: [], // Must be an array reference!
				options	: [
					{ text: this.$t('Admin'),  value: 'admin',  info: this.$t('hasFullTwo') },
					{ text: this.$t('Editor'), value: 'editor', info: this.$t('hasFull') },
					{ text: this.$t('Viewer'), value: 'viewer', info: this.$t('hasView') },
					{ text: this.$t('Custom'),  value: 'custom',  info: this.$t('hasCustom') },
					// { text: 'Leads Qualification Tool', value: 'Qualification' },
					// { text: 'Lead dispatch Tool', value: 'dispatch' },
					// { text: 'Dashboard', 			value: 'Dashboard' },
					// { text: 'Integrations', 		value: 'Integrations' }
				],
				arrayMembers : [],
				invites		 : [],
				pendingInvitation : [],
				userSelected : { id:'', name:'', email:'', role:'', role_id:'', token:'', deny_token:'', user_id:'', team_id:'' },
				loadingAction: false,
				globalLoading: false,
				LoadingAdd	 : false,
				is_empty	 : false,
				textButton	 : 'Send Invitation',
				iconButton	 : 'fa fa-send',
				textTitle	 : this.$t('InviteNewTeamMember'),
				editMode	 : false,
				currentClient: {owner_id:'', name:''},
				thisIsAdminPermissions : true,
				thisIsEditorPermissions : true,
				thisIsViewerPermissions : true,
				thisIsCustomPermissions : false,
				activatedAdmin : false,
				activatedViewer : false,
				activatedEditor : false
			}
		},
		methods: {
		
			makeViewer(){
				this.userSelected.role = "admin"

				this.checkPermissions()
			},
			setCheckBoxValuesMysources(key,item){
				if (key in this.permissionsGroups.Mysources){

					this.permissionsGroups.Mysources[key] = item
					if(key == 'list sources'){
						
						this.showSources = item
					}
				}
			},
			setCheckBoxValuesMyLeads(key,item){
				if (key in this.permissionsGroups.Myleads){
					this.permissionsGroups.Myleads[key] = item
					
				}
				
			},
			setCheckBoxValuesLeadsAccess(key,item){
				if (key in this.permissionsGroups.LeadsAccess){
					this.permissionsGroups.LeadsAccess[key] = item
					
				}
				
			},
			setCheckBoxValueSettings(key,item){
				if (key in this.permissionsGroups.Settings){
					this.permissionsGroups.Settings[key] = item
					
				}

				
			},
			setCheckBoxValueDestinations(key,item){
				if (key in this.permissionsGroups.Destinations){
					this.permissionsGroups.Destinations[key] = item
				}
				
			},
			setCheckBoxValueAnalytics(key,item){
				if (key in this.permissionsGroups.Analytics){
					this.permissionsGroups.Analytics[key] = item
				}
				
			},
			setCheckBoxValuesAnalytics(key,item){
				if (key in this.permissionsGroups.Analytics){
					this.permissionsGroups.Analytics[key] = item
				}
				
			},
			changePermission(mykey,myvalue){
				this.setCheckBoxValuesMysources(mykey,myvalue)
				this.setCheckBoxValuesMyLeads(mykey,myvalue)
				this.setCheckBoxValueSettings(mykey,myvalue)
				this.setCheckBoxValueDestinations(mykey,myvalue)
				this.setCheckBoxValuesAnalytics(mykey,myvalue)
				this.setCheckBoxValuesLeadsAccess(mykey,myvalue)
				if(mykey == 'list sources'){
					this.showSources = myvalue
				}
				if(mykey == 'list leads'){
					this.showLeads = myvalue
				}
				/*if(mykey == 'manage all leads'){
					if(myvalue){
						//this.permissionsGroups.LeadsAccess['manage assigned leads'] = true
						this.permissionsGroups.Myleads['manage assigned and unassigned leads'] = true
					}else{
						if(this.permissionsGroups.Myleads['manage assigned and unassigned leads'] == false){
							this.permissionsGroups.Myleads['manage assigned leads'] = true
						}else{
							this.permissionsGroups.Myleads['manage assigned leads'] = false
						}
					}
				}*/
				

				//if(mykey == 'manage assigned leads'){
					//if(myvalue){
						//this.permissionsGroups.LeadsAccess['manage all leads'] = false
						//this.permissionsGroups.LeadsAccess['manage assigned and unassigned leads'] = false
					//}
				//}
				/*if(mykey == 'manage assigned and unassigned leads'){
					if(myvalue){
						this.permissionsGroups.Myleads['manage all leads'] = false
						//this.permissionsGroups.LeadsAccess['manage assigned leads'] = true
					}else{
						if(this.permissionsGroups.Myleads['manage all leads']){
							this.permissionsGroups.Myleads['manage all leads'] = false
						}

						
						if(this.permissionsGroups.Myleads['manage all leads'] == false){
							this.permissionsGroups.Myleads['manage assigned leads'] = true
						}else{
							this.permissionsGroups.Myleads['manage assigned leads'] = false
						}
						
					}
				}*/
				if(mykey == 'list users'){
					this.showUsers = myvalue
					if(myvalue){
						this.permissionsGroups.Settings['add users'] = true
						this.permissionsGroups.Settings['edit users'] = true
						this.permissionsGroups.Settings['delete users'] = true
					}else{
						this.permissionsGroups.Settings['add users'] = false
						this.permissionsGroups.Settings['edit users'] = false
						this.permissionsGroups.Settings['delete users'] = false
					}
				}
				if(mykey == 'list status'){
					this.showstatus = myvalue
					if(myvalue){
						this.permissionsGroups.Settings['add status'] = true
						this.permissionsGroups.Settings['edit status'] = true
						this.permissionsGroups.Settings['delete status'] = true
					}else{
						this.permissionsGroups.Settings['add status'] = false
						this.permissionsGroups.Settings['edit status'] = false
						this.permissionsGroups.Settings['delete status'] = false
					}
				}


				
				let that = this
				
				this.thisIsAdminPermissions = true
				this.thisIsEditorPermissions = true
				this.thisIsViewerPermissions = true
				this.thisIsCustomPermissions = false

				
				//check admin permissions are checked or not
				for (const [key,value] of Object.entries(that.permissionsGroups.Mysources)) {
						if(that.permissions.roles.admin.permissions.includes(key) && value==true){
							//do things
						}else{
							this.thisIsAdminPermissions = false
						}
				}
				for (const [key,value] of Object.entries(that.permissionsGroups.LeadsAccess)) {
						if(that.permissions.roles.admin.permissions.includes(key) && value==true){
							//do things
						}else{
							this.thisIsAdminPermissions = false
						}
				}
				for (const [key,value] of Object.entries(that.permissionsGroups.Myleads)) {
						
						if(that.permissions.roles.admin.permissions.includes(key) && value==true){
							//do things
						}else{
							this.thisIsAdminPermissions = false
						}
				}

					for (const [key,value] of Object.entries(that.permissionsGroups.Destinations) ) {
						
						if(that.permissions.roles.admin.permissions.includes(key) && value==true){
							//do things
						}else{
							this.thisIsAdminPermissions = false
						}
					}
					for (const [key,value] of Object.entries(that.permissionsGroups.Analytics) ) {
						
						if(that.permissions.roles.admin.permissions.includes(key) && value==true){
							//do things
						}else{
							this.thisIsAdminPermissions = false
						}
					}
					for (const [key,value] of Object.entries(that.permissionsGroups.Settings) ) {
						
						if(that.permissions.roles.admin.permissions.includes(key) && value==true){
							//do things
						}else{
							this.thisIsAdminPermissions = false
						}
					}


				//check editor permissions are checked or not
				for (const [key, value] of Object.entries(that.permissionsGroups.Mysources)) {
						if(that.permissions.roles.editor.permissions.includes(key) ){
							if(value==true){
								//do things
							}else{
								this.thisIsEditorPermissions = false
							}
						}else{
							if(value==true){
								this.thisIsEditorPermissions = false
							}
						}
				}
				for (const [key,value] of Object.entries(that.permissionsGroups.Myleads)) {
						
						if(that.permissions.roles.editor.permissions.includes(key) ){
							if(value==true){
								//do things
							}else{
								this.thisIsEditorPermissions = false
							}
						}else{
							if(value==true){
								this.thisIsEditorPermissions = false
							}
						}
				}
				for (const [key,value] of Object.entries(that.permissionsGroups.LeadsAccess)) {
						
						if(that.permissions.roles.editor.permissions.includes(key) ){
							if(value==true){
								//do things
							}else{
								this.thisIsEditorPermissions = false
							}
						}else{
							if(value==true){
								this.thisIsEditorPermissions = false
							}
						}
				}

					for (const [key,value] of Object.entries(that.permissionsGroups.Destinations)) {
						
						if(that.permissions.roles.editor.permissions.includes(key) ){
							if(value==true){
								//do things
							}else{
								this.thisIsEditorPermissions = false
							}
						}else{
							if(value==true){
								this.thisIsEditorPermissions = false
							}
						}
					}
					for (const [key,value] of Object.entries(that.permissionsGroups.Analytics)) {
						
						if(that.permissions.roles.editor.permissions.includes(key) ){
							if(value==true){
								//do things
							}else{
								this.thisIsEditorPermissions = false
							}
						}else{
							if(value==true){
								this.thisIsEditorPermissions = false
							}
						}
					}

				
				for (const [key,value] of Object.entries(that.permissionsGroups.Settings)) {
						
						if(that.permissions.roles.editor.permissions.includes(key) ){
							if(value==true){
								//do things
							}else{
								this.thisIsEditorPermissions = false
							}
						}else{
							if(value==true){
								this.thisIsEditorPermissions = false
							}
						}
					}


				//check viewer permissions are checked or not
				for (const [key,value] of Object.entries(that.permissionsGroups.Mysources)) {
						if(that.permissions.roles.viewer.permissions.includes(key) ){
							if(value==true){
								//do things
							}else{
								this.thisIsViewerPermissions = false
							}
						}else{
							if(value==true){
								this.thisIsViewerPermissions = false
							}
						}
				}
				for (const [key,value] of Object.entries(that.permissionsGroups.Myleads)) {
						
						if(that.permissions.roles.viewer.permissions.includes(key) ){
							if(value==true){
								//do things
							}else{
								this.thisIsViewerPermissions = false
							}
						}else{
							if(value==true){
								this.thisIsViewerPermissions = false
							}
						}
					}
						for (const [key,value] of Object.entries(that.permissionsGroups.LeadsAccess)) {
						
						if(that.permissions.roles.viewer.permissions.includes(key) ){
							if(value==true){
								//do things
							}else{
								this.thisIsViewerPermissions = false
							}
						}else{
							if(value==true){
								this.thisIsViewerPermissions = false
							}
						}
					}

					for (const [key,value] of Object.entries(that.permissionsGroups.Destinations)) {
						
						if(that.permissions.roles.viewer.permissions.includes(key) ){
							if(value==true){
								//do things
							}else{
								this.thisIsViewerPermissions = false
							}
						}else{
							if(value==true){
								this.thisIsViewerPermissions = false
							}
						}
					}
						
					for (const [key,value] of Object.entries(that.permissionsGroups.Settings)) {
						
						if(that.permissions.roles.viewer.permissions.includes(key) ){
							if(value==true){
								//do things
							}else{
								this.thisIsViewerPermissions = false
							}
						}else{
							if(value==true){
								this.thisIsViewerPermissions = false
							}
						}
					}
			

				if(this.thisIsViewerPermissions){
					this.userSelected.role = "viewer"
				}else if(this.thisIsAdminPermissions){
					this.userSelected.role = "admin"
				}else if(this.thisIsEditorPermissions){
					this.userSelected.role = "editor"
				}else{
					this.userSelected.role = "custom"
				}

				

			},
			disabledPermissions(key){
				if(key == 'edit users' || key == 'add users' || key =='delete users'){
					if(this.permissionsGroups.Settings['list users'] == false){
						return true
					}else{
						return false
					}
				}
				else if(key == 'edit status' || key == 'add status' || key =='delete status'){
					if(this.permissionsGroups.Settings['list status'] == false){
						return true
					}else{
						return false
					}
				}
			},
			getTranslated(key){
				if(localStorage.getItem('i18n_lang') == 'fr'){
					return this.permissionsTranslate[key]
				}else{
					return this.permissionsTranslateEn[key]
				}
			},
			checkPermissions(){
				let that = this
				
				if(that.userSelected.role=="admin"){
					for (const [key] of Object.entries(that.permissionsGroups.Mysources)) {
						
						if(that.permissions.roles.admin.permissions.includes(key)){
							that.permissionsGroups.Mysources[key]=true
							if(key == 'list sources'){
								this.showSources = true
							}
						}else{
							that.permissionsGroups.Mysources[key]=false
							if(key == 'list sources'){
								this.showSources = false
							}
						}
					}

					for (const [key] of Object.entries(that.permissionsGroups.Myleads)) {
						
						if(that.permissions.roles.admin.permissions.includes(key)){
							that.permissionsGroups.Myleads[key]=true
							if(key == 'list leads'){
								this.showLeads = true
							}
						}else{
							that.permissionsGroups.Myleads[key]=false
							if(key == 'list leads'){
								this.showLeads = false
							}
						}
					}
						for (const [key] of Object.entries(that.permissionsGroups.LeadsAccess)) {
						
						if(that.permissions.roles.admin.permissions.includes(key)){
							that.permissionsGroups.LeadsAccess[key]=true
							
						}else{
							that.permissionsGroups.LeadsAccess[key]=false
							
						}
					}

					for (const [key] of Object.entries(that.permissionsGroups.Destinations)) {
						
						if(that.permissions.roles.admin.permissions.includes(key)){
							that.permissionsGroups.Destinations[key]=true
						}else{
							that.permissionsGroups.Destinations[key]=false
						}
					}
					for (const [key] of Object.entries(that.permissionsGroups.Analytics)) {
						
						if(that.permissions.roles.admin.permissions.includes(key)){
							that.permissionsGroups.Analytics[key]=true
						}else{
							that.permissionsGroups.Analytics[key]=false
						}
					}

					for (const [key] of Object.entries(that.permissionsGroups.Settings)) {
						
						if(that.permissions.roles.admin.permissions.includes(key)){
							that.permissionsGroups.Settings[key]=true
						}else{
							that.permissionsGroups.Settings[key]=false
						}
					}


					
				}
				if(that.userSelected.role=="viewer"){
					for (const [key] of Object.entries(that.permissionsGroups.Mysources)) {
						
						if(that.permissions.roles.viewer.permissions.includes(key)){
							that.permissionsGroups.Mysources[key]=true
							if(key == 'list sources'){
								this.showSources = true
							}
						}else{
							that.permissionsGroups.Mysources[key]=false
							if(key == 'list sources'){
								this.showSources = false
							}
						}
					}

					for (const [key] of Object.entries(that.permissionsGroups.Myleads)) {
						
						if(that.permissions.roles.viewer.permissions.includes(key)){
							that.permissionsGroups.Myleads[key]=true
							if(key == 'list leads'){
								this.showLeads = true
							}
						}else{
							that.permissionsGroups.Myleads[key]=false
							if(key == 'list leads'){
								this.showLeads = false
							}
						}
					}
					for (const [key] of Object.entries(that.permissionsGroups.LeadsAccess)) {
						
						if(that.permissions.roles.viewer.permissions.includes(key)){
							that.permissionsGroups.LeadsAccess[key]=true
						}else{
							that.permissionsGroups.LeadsAccess[key]=false
						}
					}

					for (const [key] of Object.entries(that.permissionsGroups.Destinations)) {
						
						if(that.permissions.roles.viewer.permissions.includes(key)){
							that.permissionsGroups.Destinations[key]=true
						}else{
							that.permissionsGroups.Destinations[key]=false
						}
					}

					for (const [key] of Object.entries(that.permissionsGroups.Analytics)) {
						
						if(that.permissions.roles.viewer.permissions.includes(key)){
							that.permissionsGroups.Analytics[key]=true
						}else{
							that.permissionsGroups.Analytics[key]=false
						}
					}

					for (const [key] of Object.entries(that.permissionsGroups.Settings)) {
						
						if(that.permissions.roles.viewer.permissions.includes(key)){
							that.permissionsGroups.Settings[key]=true
						}else{
							that.permissionsGroups.Settings[key]=false
						}
					}


				}
				if(that.userSelected.role=="editor"){
					for (const [key] of Object.entries(that.permissionsGroups.Mysources)) {
						
						if(that.permissions.roles.editor.permissions.includes(key)){
							that.permissionsGroups.Mysources[key]=true
							if(key == 'list sources'){
								this.showSources = true
							}
						}else{
							that.permissionsGroups.Mysources[key]=false
							if(key == 'list sources'){
								this.showSources = false
							}
						}
					}

					for (const [key] of Object.entries(that.permissionsGroups.Myleads)) {
						
						if(that.permissions.roles.editor.permissions.includes(key)){
							that.permissionsGroups.Myleads[key]=true
							if(key == 'list leads'){
								this.showLeads = true
							}
						}else{
							that.permissionsGroups.Myleads[key]=false
							if(key == 'list leads'){
								this.showLeads = false
							}
						}
					}
					for (const [key] of Object.entries(that.permissionsGroups.LeadsAccess)) {
						
						if(that.permissions.roles.viewer.permissions.includes(key)){
							that.permissionsGroups.LeadsAccess[key]=true
						}else{
							that.permissionsGroups.LeadsAccess[key]=false
						}
					}
					for (const [key] of Object.entries(that.permissionsGroups.Destinations)) {
						
						if(that.permissions.roles.editor.permissions.includes(key)){
							that.permissionsGroups.Destinations[key]=true
						}else{
							that.permissionsGroups.Destinations[key]=false
						}
					}

					for (const [key] of Object.entries(that.permissionsGroups.Analytics)) {
						
						if(that.permissions.roles.editor.permissions.includes(key)){
							that.permissionsGroups.Analytics[key]=true
						}else{
							that.permissionsGroups.Analytics[key]=false
						}
					}

					for (const [key] of Object.entries(that.permissionsGroups.Settings)) {
						
						if(that.permissions.roles.editor.permissions.includes(key)){
							that.permissionsGroups.Settings[key]=true
						}else{
							that.permissionsGroups.Settings[key]=false
						}
					}
				}
			},
			notifyMe(message, variant) {
				this.$notify({
					group	: 'foo',
					// title: 'Important message',
					text	: message,
					type	: variant,
					// duration: 500000,
					// speed	: 1000
				});
				
			},
			
			getTeam() {
				let that = this
				
				let url   = '/teammember/teamMembers'
				let method= 'POST'
				let data  = {}
				that.globalLoading = true
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					that.arrayMembers = []
					let members = response.data.members
					// console.log(response.data.members)
					members.forEach( function(member) {
						that.arrayMembers.push({id: member.id, name: member.name, email: member.email, role: member.role, role_id: member.role_id})
					} );
					that.globalLoading = false
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.globalLoading = false
				})
				
			},
			CopyText(data) {
				var result = false;
				try {
					navigator.clipboard.writeText(data)
					result = true;
					return result;
				} catch (err) {
					console.error('Failed !');
				}
			},			
			doCopy: function (data) {
				let that = this
				var result = that.CopyText(data);
				if(result == true) {
					that.notifyMe('Copied..', 'success')
				} else {
					that.notifyMe('Can not copy..', 'warn')
				}
			},
			userAction( action, user ) {
				let that = this
				if( action == 'link'){
					this.myLink = user.link
					that.$bvModal.show('bv-modal-link')
				}
				if( action == 'edit' ) {
					
					that.textButton		= this.$t("Edit")
					that.iconButton		= "fa fa-pencil"
					that.textTitle		= "Edit User Role"
					that.editMode		= true
					that.userSelected.id	= user.id
					that.userSelected.email	= user.email
					that.userSelected.role	= typeof( user.role ) != 'undefined' ? user.role : ''
					that.userSelected.role_id	= typeof( user.role_id ) != 'undefined' ? user.role_id : ''
					that.ShowLink = false
					this.getPermissions('edit')
					that.$bvModal.show('bv-modal-users')
					
				} else if( action == 'remove' ) {
					
					that.userSelected.id	= user.id
					that.userSelected.email	= user.email
					that.$bvModal.show('modal-delete-user')
					
				} else if( action == 'resend' ) {
					
					let id = user.id
					
					let url   = '/teammember/resendInvite'
					let method= 'POST'
					let data  = {id:id}
					that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
						that.getTeam()
						that.getInvitations()
						that.notifyMe(this.$t('ChangesSaved'), 'success')
						that.ShowLink = true
						that.myLink = response.data.url
						//that.$bvModal.hide('bv-modal-users')
					}).catch(( {response} ) => {
						that.notifyMe(response.data.message, 'warn')
					})
					
				} else if( action == 'cancel' ) {
					
					that.userSelected.id		= user.id
					that.userSelected.email		= user.email
					that.userSelected.deny_token= user.deny_token
					that.$bvModal.show('modal-cancel-invitation')
					
				} else if( action == 'accept' ) {
					
					let token  = user.token
					that.globalLoading = true
					let url   = '/teammember/accept'
					let method= 'POST'
					let data  = {token:token}
					that.$store.dispatch('apiRequest', { url, method, data } ).then(() => {
						that.getTeam()
						that.getInvitations()
						that.notifyMe(this.$t('ChangesSaved'), 'success')
						// that.$bvModal.hide('bv-modal-users')
						that.globalLoading = false
						let url   = '/permissions'
						let method= 'POST'
						let data  = {custom : true}
							
						that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
								
							this.$store.state.permissions = response.data
							//console.log('huss',this.$store.state.permissions)
								
						}).catch(( {response} ) => {
							console.log(response)
								
						})
							url   = 'client/selectclient'
							method= 'POST'
							data  = {}
							
							that.$store.dispatch('apiRequest', { url, method, data } ).then(() => {
								location.reload()
								that.loadingAction = false
							}).catch((  ) => {
								location.reload()
								that.loadingAction = false
							})
					
					}).catch(( {response} ) => {
						that.notifyMe(response.data.message, 'warn')
						that.globalLoading = false
					})
				}
			},
			
			CancelInvit () {
				let that = this
				let deny_token  	= that.userSelected.deny_token
				that.loadingAction 	= true
				let url   = '/teammember/cancelInvitation'
				let method= 'POST'
				let data  = {deny_token:deny_token}
				that.$store.dispatch('apiRequest', { url, method, data } ).then(() => {
					that.loadingAction = false
					that.$bvModal.hide('modal-cancel-invitation')
					that.notifyMe(this.$t('ChangesSaved'), 'success')
					that.getTeam()
					that.getInvitations()
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.loadingAction = false
				})
				
			},
			
			deleteUser() {
				let that = this
				that.loadingAction 	= true
				let team 		 	= that.$auth.user().current_team_id
				
				let url   = '/teammember/removeMember'
				let method= 'POST'
				let data  = {user_id:that.userSelected.id, team_id:team, provider : "delete users", permission_id : 6}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then(() => {
					that.getTeam()
					that.getInvitations()
					that.loadingAction 	 = false
					that.notifyMe(this.$t('ChangesSaved'), 'success')
					that.$bvModal.hide('modal-delete-user')
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.loadingAction 	 = false
				})
				
			},
			
			AddMember() {
				let that = this
				that.userSelected.name	= ''
				that.userSelected.email	= ''
				that.userSelected.role	= 'admin'
				that.ShowLink = false
				that.$bvModal.show('bv-modal-users')
				
				that.editMode		= false
				that.textButton		= that.$t("SendInvitation")
				that.iconButton		= "fa fa-send"
				that.textTitle		= that.$t("InviteNewTeamMember")
			},
			
			CheckPlans() {
				
				let that = this
				that.LoadingAdd = true
				let url   = '/user/can'
				let method= 'GET'
				let data  = { action: 'member' }
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					if( response.data.can ) {
						that.AddMember()
					} else {
						that.$bvModal.show('modal-check-access')
					}
					that.LoadingAdd = false
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.LoadingAdd = false
				})
				
			},
			
			sendInvitation() {
				
				this.customPermissions = []
				if(this.userSelected.role=="custom"){
					for (const [key] of Object.entries(this.permissionsGroups.Myleads)) {
						if(this.permissionsGroups.Myleads[key]==true){
							this.customPermissions.push(key)
						}
					}
					for (const [key] of Object.entries(this.permissionsGroups.LeadsAccess)) {
						if(this.permissionsGroups.LeadsAccess[key]==true){
							this.customPermissions.push(key)
						}
					}
					for (const [key] of Object.entries(this.permissionsGroups.Mysources)) {
						if(this.permissionsGroups.Mysources[key]==true){
							this.customPermissions.push(key)
						}
					}
					for (const [key] of Object.entries(this.permissionsGroups.Settings)) {
						if(this.permissionsGroups.Settings[key]==true){
							this.customPermissions.push(key)
						}
					}
					for (const [key] of Object.entries(this.permissionsGroups.Destinations)) {
						if(this.permissionsGroups.Destinations[key]==true){
							this.customPermissions.push(key)
						}
					}
					for (const [key] of Object.entries(this.permissionsGroups.Analytics)) {
						if(this.permissionsGroups.Analytics[key]==true){
							this.customPermissions.push(key)
						}
					}
				}
				//console.log(this.customPermissions)
				
				let that  = this
				that.is_empty = false
				let email = that.userSelected.email.trim()
				let user_id  = that.userSelected.id
				let team  = that.$auth.user().current_team_id
				let role  = that.userSelected.role
				let role_id  = that.userSelected.role_id
				let url   = '/teammember/invitationToJoinTeam'
				let data  = {email:email, team:team, role:role, provider : "add users", permission_id : 4, invited_id: this.userSelected.id,permissions:this.customPermissions}
				if( that.editMode ) {
					let client = JSON.parse( localStorage.getItem('oml-client') )
					url   = '/teammember/editAccess'
					data  = {client_id:client.id, role_id:role_id, role:role, user_id:user_id, provider : "edit users", permission_id : 5, invited_id: this.userSelected.id}
					
				}
				if( email != "" ) {
					that.loadingAction = true
					
					let method= 'POST'
					that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
						that.getTeam()
						that.getInvitations()
						that.loadingAction = false
						that.notifyMe(this.$t('ChangesSaved'), 'success')
						if(that.editMode){
							that.ShowLink = false
						}else{
							that.ShowLink = true
						}
					
						that.myLink = response.data.url
						
						//that.$bvModal.hide('bv-modal-users')

						let client = JSON.parse( localStorage.getItem('oml-client') )
						url   = '/accessCustom'
						data  = {role:role, permissions:this.customPermissions, role_id:role_id, client_id:client.id, user_id:user_id, provider : "edit users", permission_id : 5, invited_id: this.userSelected.id}
						if(that.editMode){
							that.$store.dispatch('apiRequest', { url, method, data } ).then(() => {
								that.loadingAction = false
								//that.notifyMe('<div id="member-success-added">Permissions saved</div>', 'success')
								
								if(that.editMode){
									that.$bvModal.hide('bv-modal-users')
								}
							}).catch(( {response} ) => {
								that.notifyMe(response.data.message, 'warn')
								that.loadingAction 	 = false
							})
						}
					}).catch(( {response} ) => {
						that.notifyMe(response.data.message, 'warn')
						that.loadingAction 	 = false
					})
					
					
				} else {
					that.is_empty = true
				}
			},
			getInvitations() {
				let that = this
				
				let url   = '/teammember/pendingInvitation'
				let method= 'POST'
				let data  = {}
				that.globalLoading = true
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					that.pendingInvitation = []
					that.invites = []
					let invitations = response.data.invitations
					let invites		= response.data.invites
					invitations.forEach( function(invitation) {
						that.pendingInvitation.push({
							id			: invitation.id,
							role		: invitation.roles,
							email		: invitation.email,
							invite_date : invitation.invite_date,
							token		: invitation.token,
							deny_token	: invitation.deny_token,
							user_id		: invitation.user_id,
							team_id		: invitation.team_id,
							link		: invitation.url
						})
					} );
					invites.forEach( function(invitation) {
						that.invites.push({
							id			: invitation.id,
							user_id		: invitation.user_id,
							team_id		: invitation.team_id,
							email		: invitation.email,
							token		: invitation.token,
							deny_token	: invitation.deny_token,
							role		: invitation.roles,
							invite_date : invitation.invite_date,
							team_name	: invitation.team_name,
						})
					} );
					that.globalLoading = false
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.globalLoading = false
				})
				
			},
			getPermissions(action) {
				let that = this
				
				let url   = '/permissions'
				let method= 'POST'
				let user_id =0
				let data = {}
				if(action == "edit"){
					user_id = this.userSelected.id
					data  = {user_id : user_id}
					//url   = '/accessCustom'
				}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					//this.permissions = response.data
					//edit view
					
					this.activatedAdmin = response.data.roles.admin.active;
					this.activatedViewer = response.data.roles.viewer.active;
					this.activatedEditor = response.data.roles.editor.active;

					if(data.user_id && response.data.roles.custom.active){
						for (const [item,key] of Object.entries(this.permissionsGroups.Mysources)) {
								console.log(key + 'dsd')
								if(response.data.roles.custom.permissions[item]){
									this.changePermission(item,true)
								}else{
									this.changePermission(item,false)
								}
						}
						for (const [item,key] of Object.entries(this.permissionsGroups.Myleads)) {
							console.log(key)	
									if(response.data.roles.custom.permissions[item]){
									this.changePermission(item,true)
								}else{
									this.changePermission(item,false)
								}
						}
						for (const [item,key] of Object.entries(this.permissionsGroups.LeadsAccess) ) {
							console.log(key)
								if(response.data.roles.custom.permissions[item]){
									this.changePermission(item,true)
								}else{
									this.changePermission(item,false)
								}
						}
						for (const [item,key] of Object.entries(this.permissionsGroups.Destinations) ) {
							console.log(key)
								if(response.data.roles.custom.permissions[item]){
									this.changePermission(item,true)
								}else{
									this.changePermission(item,false)
								}
						}
						for (const [item,key] of Object.entries(this.permissionsGroups.Analytics) ) {
							console.log(key)
								if(response.data.roles.custom.permissions[item]){
									this.changePermission(item,true)
								}else{
									this.changePermission(item,false)
								}
						}
						for (const [item,key] of Object.entries(this.permissionsGroups.Settings) ) {
							console.log(key)
							if(response.data.roles.custom.permissions[item]){
									this.changePermission(item,true)
								}else{
									this.changePermission(item,false)
								}
						} 						
					}
				
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
				})
				
			},
			changeSettingsState(action){
				if(action){
					this.showSettings = true
					this.permissionsGroups.Settings['list users'] = true
					this.permissionsGroups.Settings['list status'] = true
				}else{
					this.showSettings = false
					this.permissionsGroups.Settings['list users'] = false
					this.permissionsGroups.Settings['list status'] = false
				}
			}
		},
		mounted() {
			console.log('mypersmissin',this.$store.state.permissions)
			this.getTeam();
			this.getPermissions('get');
			
			
			this.getInvitations();
			this.$store.state.selectedPage = 'team'
			
			this.currentClient = JSON.parse( localStorage.getItem('oml-client') )
			// console.log( this.currentClient.owner_id )
			// console.log( this.$auth.user().id )
			// owner_id
			this.makeViewer();
			
		},
		
		filters: {
			
			maxLength: function (str) {
				if( str != null && str != '' ) {
					return str.length > 22 ? str.substring(0, 22) + "...." : str
				} else {
					return ''
				}
			}
		},
		
	}
</script>

<style lang="scss" scoped>



	.not-collapsed .fa-chevron-down{display:none}
	.collapsed .fa-chevron-up{display:none}
	#thelink{font-size: 12px;
    background: #f1f3f4;
    padding: 10px;
    border-radius: 3px;
    color: #5f6368;
    overflow: hidden;
    width: 100%;
    float: left;cursor:pointer}
	.btn-secondary[href]:hover, .btn-secondary[href]:focus, .btn-secondary[href]:active, .btn-secondary[type]:hover, .btn-secondary[type]:focus, .btn-secondary[type]:active {
		box-shadow: none;
	}
	.permission label,.rdios label{cursor:pointer;font-size:14px}
	.custom-control-input:checked ~ .custom-control-label::after {
	margin-left: 35px !important;
    background-image: url("../../../../public/img/arrow.png")!important;
    position: relative;
    width: 60px;
    height: 35px;
	}
	.custom-control-inline {
		width: 130px!important;
		margin-right: 10px;

	}
	
	[role-tooltip] {
		position:relative;
	}
	[role-tooltip]:before {
		
		position: absolute;
		left: -52px;
		top: -52px;
		font-size: 11px;
		height: 4.7em;
		background-color: #171717;
		color: #ffffff;
		border-radius: 5px;
		padding: 5px 8px;
		content: attr(content);
		display: none;
		transition: .25s ease-in-out;
		font-weight: 600;
		width: 37em;
		
	}
	[role-tooltip]:after {
		position: absolute;
		right: 30%;
		top: -4px;
		border-top: 7px solid #171717;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		content: "";
		display: none;
		transition:.25s ease-in-out;
	}
	[role-tooltip]:hover:after, [role-tooltip]:hover:before {
		display: block;
		transition:.25s ease-in-out;
	}
	
	.permissions{    padding: 10px!important;
    box-shadow: -1px -2px 16px #eaeaea;
    box-sizing: border-box;
    border-radius: 5px;
       margin-top: -5px;
    background: #fff;}
	.buttoncollapse {
    background-color: #fff!important;
    border: 0px;
    color: #000;
    text-align: left;
	}
	.buttoncollapse:hover,.buttoncollapse:active,.buttoncollapse:visited,.buttoncollapse:focus{
		box-shadow: none;
		color: #000!important;
	}
	.accordion{margin-top:10px}
	.permission{float: left;
    
    margin-left: 2%;margin-top:5px;   }
	.fa-chevron-down,.fa-chevron-up{float: right;
    color: #dbdbdb;
    background: #f1f1f1;
    border-radius: 50px;
    padding: 5px;
    font-size: 11px}
	.fa-chevron-down:hover,.fa-chevron-up:hover{float: right;
    color: #99a7e3;
    background: #f4f6fe;
    border-radius: 50px;
    padding: 5px;
    font-size: 11px}
	.permission label{    font-size: 13px;color: #6c6c6c;}
	.permission label input{height: 12px;}
	#accordion-4 .form-contol label{    font-size: 13px;}
	.heading-invit-membre{
		padding:0 !important;
	}
	
	
	.member-table{
		thead{
			th{
				& > div{
					color: #8c8c8c;
					font-weight: normal;
					font-size: 14px;
					display:flex;
					align-items:center;
					justify-content:space-between;
					
					.fa{
						opacity: 0.66;
					}
				}
			}
		}

		tbody{
			td{
				height:35px;
			}
		}
		
	}

</style>
